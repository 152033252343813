/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Subtitle, Title, Button, Text, ColumnCover, ColumnDiv, PriceWrap, ContactForm } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1j0a0zb bg--top --full" name={"uvod-1"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 pb--40 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/o/g/Ikonky/Vinarsvi/vinarstvi-22.svg"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":110}} srcSet={""} svgColor={"rgba(255,255,255,1)"}>
              </Image>

              <Subtitle className="subtitle-box subtitle-box--style5 subtitle-box--center fs--30 w--300 swpf--uppercase ls--50 mt--25" content={"<span style=\"color: white;\">Vinařství</span>"}>
              </Subtitle>

              <Title className="title-box fs--185 w--300" style={{"marginTop":35}} content={"<span style=\"color: white;\">Jiří Stloukal</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shadow4 ls--08 mt--25" href={"#cenik-1"} content={"Vinní lístek"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--60" name={"uvod-2"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="mt--10">
              
              <Title className="title-box fs--48 w--300 ls--001 lh--12" style={{"maxWidth":1000}} content={"„Staré dřevo nejlépe hoří, staré víno se nejlépe pije, starého autora nejraději čti.“"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":950}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"navigace"} layout={"l30"}>
          
          <ColumnWrap className="column__flex --center el--2 pl--0 pr--0 flex--center" style={{"maxWidth":""}} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper className="mb--0 mt--0 pb--0 pt--0">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/77/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/77/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/77/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/77/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/77/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/77/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr2 btn-box--shadow4 fs--26" content={"Kulinářské kurzy"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

            <ColumnWrapper className="mb--0 mt--0 pb--0 pt--0">
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/77/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/77/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/77/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/77/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/77/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/77/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <ColumnCover >
                
                <ColumnWrapper className="--center flex--center">
                  
                  <ColumnDiv >
                    
                    <Button className="btn-box btn-box--hvr2 btn-box--shadow4 fs--26" content={"Degustační večírky"}>
                    </Button>

                  </ColumnDiv>

                </ColumnWrapper>

              </ColumnCover>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-zt7amw --parallax mt--80 pb--80 pt--60" name={"informace-1"} style={{"backgroundColor":"var(--color-supplementary)"}} parallax={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color-2), var(--overlay-color-2)), url(https://cdn.swbpg.com/t/i/template/77/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex el--2 flex--top" columns={"2"}>
            
            <ColumnWrapper className="--right mt--10 pr--20">
              
              <Title className="title-box fs--48 w--300" content={"<span style=\"color: white;\">Staré dřevo nejlépe hoří, staré víno se nejlépe pije.</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shadow4" content={"Vinní lístek"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10 pr--20">
              
              <Subtitle className="subtitle-box w--300" content={"<span style=\"color: white;\">— Sklenička vína denně zaručí dlouhý život</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--300" content={"<span style=\"color: white;\">— Sběr hroznů probíhá ručně</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--300" content={"<span style=\"color: white;\">— Naše víno zraje v kvalitních dubových sudech</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--300" content={"<span style=\"color: white;\">— Vinice se rozléhají na Jižní Moravě</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20 pt--80" name={"cenik-1"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--20" columns={"1"}>
            
            <ColumnWrapper className="mt--10">
              
              <Title className="title-box fs--102 w--300 lh--12" content={"Vinní lístek"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":640}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mb--50 pb--30 pt--30" name={"cenik-2"}>
          
          <ColumnWrap className="column__flex el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 pb--0" content={"Frankovka modrá (2008)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 pb--0" content={"Frankovka modrá (2008)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 pb--0" content={"Frankovka modrá (2008)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 pb--0" content={"Frankovka modrá (2008)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 pb--0" content={"Frankovka modrá (2008)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 pb--0" content={"Frankovka modrá (2008)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 pb--0" content={"Frankovka modrá (2008)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 pb--0" content={"Frankovka modrá (2008)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 pb--0" content={"Frankovka modrá (2008)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 pb--0" content={"Frankovka modrá (2008)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 pb--0" content={"Frankovka modrá (2008)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 pb--0" content={"Frankovka modrá (2008)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 pb--0" content={"Frankovka modrá (2008)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 pb--0" content={"Frankovka modrá (2008)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

              <PriceWrap className="pb--06 pl--16 pr--16 pt--06" style={{"backgroundColor":"var(--color-blend--95)"}}>
                
                <ColumnWrapper >
                  
                  <Text className="text-box fs--20 pb--0" content={"Frankovka modrá (2008)"}>
                  </Text>

                </ColumnWrapper>

                <ColumnWrapper className="--right">
                  
                  <Subtitle className="subtitle-box pb--0 pt--02" content={"179,— Kč"}>
                  </Subtitle>

                </ColumnWrapper>

              </PriceWrap>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mt--40 pb--0 pt--0" name={"fotogalerie"} layout={"l12"} lightbox={true}>
          
          <ColumnWrap className="column__flex --center el--4 --full" columns={"4"} fullscreen={true}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/77/img-1_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/77/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/77/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/77/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/77/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/77/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/77/img-2_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/77/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/77/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/77/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/77/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/77/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/77/fullscreen_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/77/fullscreen_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/77/fullscreen_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/77/fullscreen_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/77/fullscreen_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/77/fullscreen_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/77/img-3_s=350x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/77/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/77/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/77/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/77/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/77/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mt--0 pb--80 pt--80" name={"navigace-2"}>
          
          <ColumnWrap className="column__flex --center el--2 mb--20 flex--stretch" columns={"2"}>
            
            <ColumnWrapper className="mt--10 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Title className="title-box" content={"Degustační večírky"}>
              </Title>

              <Text className="text-box mb--30" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Sklenička vína denně zaručí dlouhý život"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Sběr hroznů probíhá ručně"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Naše víno zraje v kvalitních dubových sudech"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Sběr hroznů probíhá ručně"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shadow4 mt--25" href={"#kontakt-1"} content={"Zamluvit termín"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Title className="title-box" content={"Kulinářské kurzy"}>
              </Title>

              <Text className="text-box mb--30" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Sklenička vína denně zaručí dlouhý život"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Sběr hroznů probíhá ručně"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Naše víno zraje v kvalitních dubových sudech"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Sběr hroznů probíhá ručně"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shadow4 mt--25" href={"#kontakt-1"} content={"Zamluvit termín"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Title className="title-box" content={"Prohlídky vinic"}>
              </Title>

              <Text className="text-box mb--30" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Sklenička vína denně zaručí dlouhý život"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Sběr hroznů probíhá ručně"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Naše víno zraje v kvalitních dubových sudech"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Sběr hroznů probíhá ručně"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shadow4 mt--25" href={"#kontakt-1"} content={"Zamluvit termín"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10 pb--40 pl--40 pr--40 pt--40" style={{"backgroundColor":"var(--color-blend--95)"}}>
              
              <Title className="title-box" content={"Firemní akce"}>
              </Title>

              <Text className="text-box mb--30" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Sklenička vína denně zaručí dlouhý život"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Sběr hroznů probíhá ručně"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Naše víno zraje v kvalitních dubových sudech"}>
              </Subtitle>

              <Subtitle className="subtitle-box fs--22 w--300" content={"— Sběr hroznů probíhá ručně"}>
              </Subtitle>

              <Button className="btn-box btn-box--hvr2 btn-box--shadow4 mt--25" href={"#kontakt-1"} content={"Zamluvit termín"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-o3yuya bg--bottom pb--80" name={"informace-2"} css={css`
        
    background-image: url(https://cdn.swbpg.com/t/7818/61da9a3e8b90484fb6ce301d3323f219_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7818/61da9a3e8b90484fb6ce301d3323f219_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/7818/61da9a3e8b90484fb6ce301d3323f219_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7818/61da9a3e8b90484fb6ce301d3323f219_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/7818/61da9a3e8b90484fb6ce301d3323f219_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/7818/61da9a3e8b90484fb6ce301d3323f219_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/7818/61da9a3e8b90484fb6ce301d3323f219_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/7818/61da9a3e8b90484fb6ce301d3323f219_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 mb--80 pb--80 pt--40" columns={"1"}>
            
            <ColumnWrapper className="mb--40 mt--10">
              
              <Title className="title-box fs--48 w--300 lh--12" style={{"maxWidth":1000}} content={"<span style='color: var(--color-dominant)'>„Staré dřevo nejlépe hoří, staré víno se nejlépe pije, starého autora nejraději čti.“</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":950}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="mt--0 pb--80 pt--60" name={"kontakt-1"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--bottom" columns={"2"}>
            
            <ColumnWrapper className="--right mt--10 pr--20">
              
              <Title className="title-box fs--48 w--300 ls--001 lh--12" content={"<span style='color: var(--color-dominant)'>Staré dřevo nejlépe hoří, staré víno se nejlépe pije.</span>"}>
              </Title>

              <Button className="btn-box btn-box--hvr2 btn-box--shadow4" content={"Vinní lístek"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10 pr--20">
              
              <Subtitle className="subtitle-box w--300" content={"<span style='color: var(--color-dominant)'>— Sklenička vína denně zaručí dlouhý život</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--300" content={"<span style='color: var(--color-dominant)'>— Sběr hroznů probíhá ručně</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--300" content={"<span style='color: var(--color-dominant)'>— Naše víno zraje v kvalitních dubových sudech</span>"}>
              </Subtitle>

              <Subtitle className="subtitle-box w--300" content={"<span style='color: var(--color-dominant)'>— Vinice se rozléhají na Jižní Moravě</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--0" name={"kontakt-2"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <ContactForm className="--shape2 --style3 --right fs--16" action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]} layout={"l3"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--80 pt--60" name={"paticka"}>
          
          <ColumnWrap className="column__flex el--4" columns={"4"}>
            
            <ColumnWrapper className="mt--10">
              
              <Title className="title-box fs--48 w--300 lh--12" content={"Stloukal"}>
              </Title>

              <Text className="text-box" content={"Klikněte a začněte psát text, tento prostor je určený pro text. Některé prvky obsahují specifické funkce."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10">
              
              <Title className="title-box fs--48 w--300 lh--12" content={"Adresa"}>
              </Title>

              <Text className="text-box" content={"Heršpacká 18/342<br> Brno 635 00<br>Česká republika"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10">
              
              <Title className="title-box fs--48 w--300 lh--12" content={"Kontakt"}>
              </Title>

              <Text className="text-box" content={"+420 797 811 2X0<br>info@vase-stranky.com<br><a href=\"https://saywebpage.com\">Tvorba web stránek</a>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right mt--10">
              
              <Text className="text-box fs--24 mt--0" content={"<a href=\"#cenik-1\">Vinní lístek</a>"}>
              </Text>

              <Text className="text-box fs--24 mt--0" content={"<a href=\"#uvod-2\">O nás</a>"}>
              </Text>

              <Text className="text-box fs--24 mt--0" content={"<a href=\"#navigace-2\">Degustace a jiné akce</a><br>"}>
              </Text>

              <Text className="text-box fs--24 mt--0" content={"<a href=\"#kontakt-1\">Kontakt</a>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}